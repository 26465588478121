export const environment = {
  production: true,
  apiBaseURL: 'https://api.wakatoon.com',
  apiKey: 'UW8G7mFEuW5hoPD2Wqtem4GhiH7sKFW6xZnP9Ksh',
  appKey: 'wktn',
  amplKey : '3e59c908931b44ebca6f518d73f838d1',
  smartUrl : 'https://link.wakatoon.com/from-promocode?ott=',
  auth2: {
    authority: 'https://pass.canal-plus.com/provider-IdPOAuth2/auth/Wakatoon',
    tokenUrl: 'https://pass.canal-plus.com/provider-IdPOAuth2/token/Wakatoon',
    redirectUrl: 'https://app.wakatoon.com/user/3rd_party_login/canalplus/redirect',
    clientId: 'wakatoon',
    clientSecret: '9FC53FCC750EAE1BC52258CE647F330B2789AB4CBA308D423A74D29320B54ACE',
    scope: 'scope1 scope2 offline_access',
    responseType: 'code',
    grantType: 'authorization_code',
  },
};
